import React from 'react';

import { EC_FAQS } from './ECFAQs'
import { MBC_FAQS, LATEST_MB_CASES } from './MBCFAQs'

export const PRODUCT_FAQS = {
  title: "FAQs",
  description: "Frequently asked questions and product guides.",
  categories: [
    {
      title: "MagBak.com Orders",
      collapsed: true,
      url: "#magbak-orders",
      questions:[
        {
          title: "What countries do you ship to?",
          answer:`
            <p>We ship to most countries around the world.</p>
          `,
          url: "#orders-countries"
        },
        {
          title: "How long does it take to ship my order after I make a purchase?",
          answer:`
            <p>It usually takes 1-2 business days to pack and ship an order after purchase.</p>
            <p>Items that are out of stock will specify the expected shipping time frame on the product page.</p>
          `,
          url: "#orders-fulfillment"
        },
        {
          title: "My order is being shipped internationally. Will I receive a customs charge?",
          answer:`
            <p>Orders that are shipped internationally may be subject to customs charges.</p>
            <p>You as the customer are responsible for all charges.</p>
          `,
          url: "#orders-customs"
        },
        {
          title: "Where can I get help to place a new order?",
          answer:`
            <p>Request help with a new order <a href="https://support.magbak.com/order/new" target="_blank">here</a>.</p>
          `,
          url: "#orders-help-new"
        },
        {
          title: "Where can I see the status of my order?",
          answer:`
            <p>Request your order status <a href="https://support.magbak.com/order/status" target="_blank">here</a>.</p>
          `,
          url: "#orders-help-status"
        },
        {
          title: "I just placed my order. How can I make changes to it?",
          answer:`
            <p>Order changes can only be requested within 1 hour of placing the order.</p>
            <p>Request order updates <a href="https://support.magbak.com/order/update" target="_blank">here</a>.</p>
          `,
          url: "#orders-help-update"
        },
        {
          title: "I received my order, but I'm missing items. What do I do?",
          answer:`
            <p>Request help with missing or incomplete items <a href="https://support.magbak.com/order/incomplete" target="_blank">here</a>.</p>
          `,
          url: "#orders-help-incomplete"
        },
        {
          title: "How can I cancel my order?",
          answer:`
            <p>Order cancelations can only be requested within 1 hour of placing the order.</p>
            <p>Request to cancel your order <a href="https://support.magbak.com/order/cancel" target="_blank">here</a>.</p>
          `,
          url: "#orders-help-cancel"
        },
      ]
    },
    {
      title: "Amazon Orders",
      collapsed: true,
      url: "#amazon-orders",
      questions:[
        {
          title: "Who can I contact if I have a question regarding my Amazon order?",
          answer:`
            <p>You can reach our support team 24/7 <a href="/order" target="_blank">here</a> with your questions.</p>
            <p>Please select the option that is most applicable. Have your amazon order number ready.</p>
          `,
          url: "#amazon-questions"
        },
        {
          title: "Where can I get help for my RimCase order from Amazon?",
          answer:`
            <p>Check out our <a href="#rimcase">FAQs</a> about the RimCase; you might find a quick solution for your issue there.</p>
            <p>If you still want to reach out to our support team, you can do so <a href="/order" target="_blank">here</a>. Please have your Amazon order number ready.</p>
          `,
          url: "#amazon-rimcase"
        },
        {
          title: "Where can I get help for my Tesla Charger order from Amazon?",
          answer:`
            <p>Check out our <a href="#tesla-charger">FAQs</a> about the Tesla Charger; you might find a quick solution for your issue there.</p>
            <p>If you still want to reach out to our support team, you can do so <a href="/order" target="_blank">here</a>. Please have your Amazon order number ready.</p>
          `,
          url: "#amazon-tesla-charger"
        },
        {
          title: "How can I request a return for my Amazon order?",
          answer:`
            <p>All returns regarding Amazon purchases must be handled through Amazon's return process.</p>
          `,
          url: "#amazon-return"
        }
      ]
    },
    {
      title: "Returns",
      collapsed: true,
      url: "#magbak-returns",
      questions:[
        {
          title: "How much time do I have to return my purchase?",
          answer:`
            <p>We offer a 30 day (from time of delivery) 100% money back guarantee on all our products.</p>
          `,
          url: "#returns-time"
        },
        {
          title: "How do I get a refund for my purchase?",
          answer:`
            <p>Refunds can only be requested within 30 days of product delivery.</p>
            <p>Request a refund for a purchase <a href="https://support.magbak.com/rma/refund" target="_blank">here</a>.</p>
          `,
          url: "#returns-refund"
        },
        /*{
          title: "Where do I ship the products that I'm returning?",
          answer:`
            <p>Ship all product returns to the following address:</p>
            <p>1Lss Inc.<br/>529 S. Broadway<br/>Suite 205<br/>Los Angeles, CA 90013</p>
            <p>Please note your order number on the package.</p>
            <p>For reference, keep track of your tracking number.</p>
          `,
          url: "#returns-ship"
        },*/
        {
          title: "How long does it take for me to get a refund?",
          answer:`
            <p>For the vast majority of returns, it takes about 5-10 Business Days for us to get your package at the fulfillment center, inspect your return, process it into inventory, and complete your refund.</p>
            <p>If the refund is being issued to a credit card, depending on your credit card company, it may take an additional 2-10 Business Days after your credit is applied for it to post to your account.</p>
          `,
          url: "#returns-refund-time"
        },
        {
          title: "How do I replace a broken or defective product?",
          answer:`
            <p>Replacements can only be requested within the product warranty. The warranty period may differ from product to product.</p>
            <p>Request a product replacement <a href="https://support.magbak.com/rma/replace" target="_blank">here</a>.</p>
          `,
          url: "#returns-replace"
        },
        {
          title: "How do I exchange a product?",
          answer:`
            <p>Exchanges can only be requested within 30 days of product delivery.</p>
            <p>Request to exchange a product <a href="https://support.magbak.com/rma/exchange" target="_blank">here</a>.</p>
          `,
          url: "#returns-exchange-2"
        },
      ]
    },
    ...MBC_FAQS.categories,
    ...EC_FAQS.categories,
    {
      title: "MagBak Wallet",
      collapsed: true,
      url: "#wallet",
      questions: [
        {
          title: "Does the wallet come with any additional attachments?",
          answer:`
            <p>No. The wallet is sold by itself.</p>
          `,
          url: "#wallet-attachments"
        },
        {
          title: "Can the wallet attach directly to a phone or do I need to use another accessory or attachment?",
          answer:`
            <p>Our new <a target="_blank" href="https://magbak.com/products/magbak-wallet">wallet</a> can attach direclty to any iPhone or case that supports MagSafe. For the best wallet and case combo check out our <a target="_blank" href="https://magbak.com/collections/magbak-case">MagBak cases</a>.</p>
          `,
          url: "#wallet-attachments-2"
        },
        {
          title: "Will the wallet work with a magnetic case that is not MagBak?",
          answer:`
            <p>Our new <a target="_blank" href="https://magbak.com/products/magbak-wallet">wallet</a> can attach direclty to a case that supports MagSafe.</p>
            <p>Other cases are NOT guaranteed to work. The wallet magnet arrangements may be different.</p>
          `,
          url: "#wallet-non-magbak"
        },
        {
          title: "Will the wallet attach directly to the iPhone with MagSafe technology?",
          answer:`
            <p>Yes! Magnetic attachment to Apple's MagSafe technology is now supported. Learn more about MagBak and MagSafe compatibility <a target="_blank" href="https://magbak.com/pages/magsafe" title="MagSafe">here</a>.</p>
          `,
          url: "#wallet-magsafe"
        },
        {
          title: "Will the wallet attach directly to a case with Apple's MagSafe technology?",
          answer:`
            <p>Yes! MagBak wallet now supports MagSafe. Learn more about MagBak and MagSafe compatibility <a target="_blank" href="https://magbak.com/pages/magsafe" title="MagSafe">here</a>.</p>
          `,
          url: "#wallet-magsafe-case"
        },
        {
          title: "What MagBak cases does the wallet support?",
          answer:`
            <p>The latest MagBak cases work with the MagBak Wallet:</p>
            <p>
              ${LATEST_MB_CASES}
            </p>
            <p>Unfortunately, older MagBak cases do NOT work with the MagBak Wallet.</p>
          `,
          url: "#wallet-case"
        },
        {
          title: "Can you wirelessly charge a phone with the wallet on? ",
          answer:`
            <p>No. There can't be an obstacle between a wireless charger and the phone.</p>
          `,
          url: "#wallet-charge"
        },
        {
          title: "Which MagStick should I use to mount with the wallet on my phone?",
          answer:`
            <p>All MagStick versions allow you to mount with the wallet attached.</p>
          `,
          url: "#wallet-magstick"
        },
        {
          title: "What’s the difference between Nappa and Saffiano wallets?",
          answer:`
            <p>Nappa has a smooth finish.</p>
            <p><img class="circle" src="/resources/img/wallet-material-nappa.jpg"/><p>
            <p>Saffiano has a textured finish.</p>
            <p><img class="circle" src="/resources/img/wallet-material-saffiano.jpg"/><p>
          `,
          url: "#wallet-finish"
        },
        {
          title: "Does the wallet come with a finger loop?",
          answer:`
            <p>Yes! All MagBak Wallets come with a finger-loop.</p>
            <p>The loop is made out of the same material as the wallet.<p>
          `,
          url: "#wallet-loop"
        },
        {
          title: "How do I remove the finger loop from the wallet?",
          answer:`
            <p>You can remove the loop if you prefer a different look by simply cutting it off.<p>
          `,
          url: "#wallet-loop-off"
        },
        {
          title: "Why is the wallet too tight?",
          answer:`
            <p>The wallet will be very tight initially.</p>
            <p>Insert 2-3 cards and allow a few days to break in before inserting more cards.</p>
          `,
          url: "#wallet-tight"
        },
        {
          title: "How many cards fit in the wallet?",
          answer:`
            <p>The wallet can fit up to 9 credit cards and a couple of dollar bills.</p>
          `,
          url: "#wallet-fit"
        },
        {
          title: "Will the wallet magnets erase my credit cards?",
          answer:`
            <p>No. Common credit cards are safe and are NOT affected. The wallet has embedded steel plates that shield magnets and protect RFID.</p>
          `,
          url: "#wallet-erase"
        },
        {
          title: "What are the dimensions of the wallet?",
          answer:`
            <p><img class="landscape" src="/resources/img/wallet-dimensions.jpg"/></p>
          `,
          url: "#wallet-dimensions"
        }
      ]
    },
    {
      title: "MagBak MultiCharger",
      collapsed: true,
      url: "#multicharger",
      questions: [
        {
          title: "Will the MultiCharger work with my phone without a MagBak case?",
          answer:`
            <p>The MultiCharger is designed to be MagSafe compatible so you should be able to use your MagSafe compatible phone or case.</p>
            <p>For enhanced magnetic strength we recommend pairing the MultiCharger Charger with <a href="https://magbak.com/pages/cases" target="_blank">MagBak case</a>.</p>
          `,
          url: "#multicharger-compatibility"
        },
        {
          title: "Why is my MultiCharger not charging fast?",
          answer:`
            <p>Try the following:</p>
            <p>
              <ol>
                <li>Use a power adapter that is compatible with USB-C PD20W.</li>
                <li>Make sure there is no gap between the device and the charger.</li>
                <li>Ensure the device and charger coils are aligned.</li>
              </ol>
            </p>
          `,
          url: "#multicharger-speed"
        },
        {
          title: "Which Apple Watch version is compatible with the MultiCharger?",
          answer:`
            <p>The MultiCharger is compatible with all versions of the Apple Watch.</p>
          `,
          url: "#multicharger-watch"
        },
        {
          title: "Which AirPods version is compatible with the MultiCharger?",
          answer:`
            <p>The MultiCharger is compatible with all AirPods verisons that support wireless charging.</p>
            <p>Keep in mind, the latest Apple AirPods have magnets for Magsafe Charging. This allows you to mount the AirPods to the front of the MultiCharger where you would usually put your phone.</p>
          `,
          url: "#multicharger-airpods"
        },
        {
          title: "What are the MultiCharger charging speeds?",
          answer:`
            <p>MultiCharger output speeds:
              <ul>
                <li>2.5W (Apple Watch) </li>
                <li>5W / 7.5W / 10W / 15W (Smart Phone*)</li>
                <li>5W (AirPods)</li>
              </ul>
            </p>
            <p>* Apple restricts 3rd party chargers at 7.5W max output.</p>
          `,
          url: "#multicharger-speed"
        },
        {
          title: "Is the MultiCharger charging speed affected when all 3 devices are charged at the same time?",
          answer:`
            <p>Charging speed should not be affected as long as the provided USB-C PD 30W power adapter is used with the MultiCharger.</p>
          `,
          url: "#multicharger-speed-2"
        },
        {
          title: "Does the MultiCharger work with any iPhone?",
          answer:`
            <p>The MultiCharger works with all iPhones that support wireless charging.</p>
            <p>Compatible setups:
              <ol>
                <li>All <a target="_blank" href="https://magbak.com/collections/magbak-case">MagBak for iPhone</a> cases.</li>
                <li>iPhones 12 & 13 with no case.</li>
                <li>All MagSafe cases.</li>
              </ol>
            </p>
          `,
          url: "#multicharger-setups"
        },
        {
          title: "Is the iPhone 11 or older iPhones compatible with the MultiCharger?",
          answer:`
            <p>Phones that don't have MagSafe and support wireles charging can still be used with the MultiCharger as long as they use a MagBak case.</p>
            <p>Here is a list of MagBak cases that support wireless charging:
              ${LATEST_MB_CASES}
            </p>
          `,
          url: "#multicharger-legacy"
        },
        {
          title: "Are Samsung phones compatible with the MultiCharger?",
          answer:`
            <p>Samsung phones can be used with the MultiCharger as long as they use a MagBak case.</p>
            <p>Here is a list of MagBak cases for Samsung that support wireless charging:
              <ul>
                <li><a target="_blank" href="https://magbak.com/products/magbak-for-galaxy-s24-series" title="Samsung S24 Series">Samsung S24 Series</a></li>
                <li><a target="_blank" href="https://magbak.com/products/magbak-for-galaxy-s23-series-magsticks-to-mount-anywhere" title="Samsung S23 Series">Samsung S23 Series</a></li>
                <li><a target="_blank" href="https://magbak.com/products/magbak-for-samsung-s22-series" title="Samsung S22 Series">Samsung S22 Series</a></li>
              </ul>
            </p>
          `,
          url: "#multicharger-samsung"
        },
        {
          title: "Are Google Pixel phones compatible with the MultiCharger?",
          answer:`
            <p>Pixel phones can be used with the MultiCharger as long as they use a MagBak case.</p>
            <p>Here is a list of MagBak cases for Pixel that support wireless charging:
              <ul>
                <li><a target="_blank" href="https://magbak.com/products/magbak-for-pixel-8-series" title="Pixel 8 Series">Pixel 8 Series</a></li>
                <li><a target="_blank" href="https://magbak.com/products/magbak-for-pixel-7-series" title="Pixel 7 Series">Pixel 7 Series</a></li>
                <li><a target="_blank" href="https://magbak.com/products/magbak-for-google-pixel-6-series-1-magstick" title="Pixel 6 Series">Pixel 6 Series</a></li>
              </ul>
            </p>
          `,
          url: "#multicharger-pixel"
        },
        {
          title: "Can the MultiCharger be moved or repositioned after it is adhered to a surface?",
          answer:`
            <p>Yes, the gecko material on the bottom of the MultiCharger is strong yet resusable.</p>
            <p>If the gecko material loses its grip, clean it with a damp lint free cloth to remove oils and debris. Do NOT use any chemicals or abrasives.</p>
          `,
          url: "#multicharger-reposition"
        },
        {
          title: "What are the MultiCharger specifications?",
          answer:`
            <p>
              <ul>
                <li>Model: MBQIML</li>
                <li>Material: Aluminum</li>
                <li>Power Input: USB-C PD 20W</li>
                <li>Dimensions: 115.5 x 98.1 x 54.2 (mm)</li>
                <li>Weight: 135 (g)</li>
                <li>Cable Length: 1.5 (m)</li>
              </ul>
            </p>
            <p><img class="landscape" src="/resources/svg/multicharger-overview.svg"/></p>
          `,
          url: "#multicharger-specs"
        },
        {
          title: "Where can I find the MultiCharger manual?",
          answer:`
            <p>You can find the MultiCharger manual PDF <a href="/resources/pdf/MultiCharger-User-Manual-rev3.pdf" target="_blank">here</a>.</p>
          `,
          url: "#multicharger-manual"
        }
      ]
    },
    {
      title: "MagBak Charger",
      collapsed: true,
      url: "#charger",
      questions: [
        {
          title: "Will the MagBak Charger work with my phone without a MagBak case?",
          answer:`
            <p>The MagBak Charger is designed to be MagSafe compatible so you should be able to use your MagSafe compatible phone or case.</p>
            <p>For enhanced magnetic strength we recommend pairing the MagBak Charger with <a href="https://magbak.com/pages/cases" target="_blank">MagBak case</a>.</p>
          `,
          url: "#charger-compatibility"
        },
        {
          title: "Why is my charger not charging fast?",
          answer:`
            <p>Try the following:</p>
            <p>
              <ol>
                <li>Use a power adapter that can supply at least 9V at 1.5A.</li>
                <li>Make sure there is no gap between the phone and the charger.</li>
                <li>Ensure the phone and charger coils are aligned.</li>
              </ol>
            </p>
          `,
          url: "#charger-speed"
        },
        {
          title: "Will my phone work with the MagBak wireless charger?",
          answer:`
            <p>The charger works with any phone that supports the Qi wireless charging standard.</p>
            <p>It is designed to work with the MagBak case. This will provide mounting auto-alignment in portrait and landscape.</p>
            <p>Additionally, the charger is MagSafe compatible so you should be able to use your MagSafe compatible phone or case.</p>
          `,
          url: "#charger-phone"
        },
        {
          title: "What MagBak cases work with the MagBak wireless charger?",
          answer:`
            <p>The latest MagBak cases work with the charger</p>
            <p>
              ${LATEST_MB_CASES}
            </p>
            <p>Unfortunately, older MagBak cases do NOT work with the MagBak wireless charger</p>
          `,
          url: "#charger-case"
        },
        {
          title: "My phone has the MagStache on it. Can I use the wireless charger?",
          answer:`
            <p>You can use MagStache with the charger. MagStache will allow mounting and auto-alignment in portrait mode only.</p>
          `,
          url: "#charger-stache"
        },
        {
          title: "Does the MagBak Stand come with a charger?",
          answer:`
            <p>The MagBak Stand is sold separately.</p>
          `,
          url: "#charger-stand"
        },
        {
          title: "Which USB power adapter should I use?",
          answer:`
            <p>For U.S. homes use the U.S. Charger Kit adapter.</p>
            <p>If your country supports U.K. style wall plug, use the U.K. Charger Kit.</p>
            <p>For countries that don't support U.S. or U.K wall plugs, you can purchase an outlet converter.</p>
          `,
          url: "#charger-adapter"
        },
        {
          title: "Does Apple's CarPlay work if I'm charging my phone with the MagBak Wireless charger?",
          answer:`
            <p>Most cars require the iPhone to be directly connected via lightning cable to support CarPlay. This defeats the purpose of wireless charging.</p>
            <p>A growing number of cars are starting to support Wireless CarPlay with a combination of Wifi and Bluetooth connections.</p>
            <p>Please refer to your car owner's manual to see if your car supports Wireless CarPlay.</p>
          `,
          url: "#charger-carplay"
        },
        {
          title: "Will the MagBak wireless charger work with Apple's smart battery case?",
          answer:`
            <p>The wireless charger should work with Apple's smart battery case (if it supports Qi wireless charging).</p>
            <p>Please note that magnetic mounting is NOT supported.</p>
          `,
          url: "#charger-battery-case"
        },
        {
          title: "Does the MagBak wireless charger work with Apple's MagSafe technology?",
          answer:`
            <p>The wireless charger will charge an iPhone that has MagSafe.</p>
            <p>The latest version of the charger now supports MagSafe magnetic mounting.</p>
          `,
          url: "#charger-magsafe"
        },
        {
          title: "Will the MagBak wireless charger work with my AirPods?",
          answer:`
            <p>Yes! The wireless chager will charge your AirPods (if your AirPod case supports Qi wireless charging)</p>
            <p>Please note that magnetic mounting is only supported for the 1st gen AirPods.</p>
          `,
          url: "#charger-airpods"
        },
        {
          title: "Where can I find the MagBak Charger manual?",
          answer:`
            <p>You can find the MagBak Charger manual PDF <a href="/resources/pdf/MBQi-Charger-Manual-2022.pdf" target="_blank">here</a>.</p>
          `,
          url: "#charger-manual"
        }
      ]
    },
    {
      title: "MagBak Charger for Tesla (Model S | 3 | X | Y and Cybertruck)",
      collapsed: true,
      url: "#tesla-charger",
      questions: [
        {
          title: "Will the Tesla Charger work with my phone without a MagBak case?",
          answer:`
            <p>The Tesla Charger is designed to be MagSafe compatible so you should be able to use your MagSafe compatible phone or case.</p>
            <p>For enhanced magnetic strength we recommend pairing the Tesla Charger with <a href="https://magbak.com/pages/cases" target="_blank">MagBak case</a>.</p>
          `,
          url: "#mb3y-compatibility"
        },
        {
          title: "How do I install the charger on my Tesla vehicle?",
          answer:`
            <p>Watch the installation instruction videos.</p>
            <p>
              <ul>
                <li>
                  Model 3 (2017-2023) | Model Y (All years)<br>
                  <iframe class="landscape" src="https://player.vimeo.com/video/659190813" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </li>
              </ul>
            </p>
            <p>
              <ul>
                <li>
                  Model 3 (2024)<br>
                  <iframe class="landscape" src="https://player.vimeo.com/video/931238952" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </li>
              </ul>
            </p>
            <p>
              <ul>
                <li>
                  Model S | Model X (2021+)<br>
                  <iframe class="landscape" src="https://player.vimeo.com/video/963898171" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </li>
              </ul>
            </p>
            <p>
              <ul>
                <li>
                  Cybertruck<br>
                  <iframe class="landscape" src="https://player.vimeo.com/video/928061828" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </li>
              </ul>
            </p>
            <p>Installation steps:</p>
            <ol>
              <li>Clean the back top left corner of the screen using the provided alcohol pad.</li>
              <li>Peel off the 3M adhesive backing from the charger.</li>
              <li>Carefully align the top edge of the charger and the right edge of the charger with the screen on the top left corner.<br/><img class="landscape" src="/resources/svg/mb-tesla-install-1.svg"/></li>
              <li>Press the charger to adhere it to the left edge of the screen.<br/><img class="landscape" src="/resources/svg/mb-tesla-install-2.svg"/></li>
              <li>Press the charger to adhere it to the back of the screen.<br/><img class="landscape" src="/resources/svg/mb-tesla-install-3.svg"/></li>
            </ol>
            <p>Wait at least 5 minutes before attempting to use the charger to ensure proper adhesion.</p>
          `,
          url: "#mb3y-install"
        },
        {
          title: "How do I route the MagBak Charger for Tesla USB-C cable?",
          answer:`
            <p>Watch the installation instructions video for suggestions on how to route the USB-C cable.</p>
          `,
          url: "#mb3y-cable"
        },
        {
          title: "Can the MagBak Charger for Tesla be installed anywhere else other than the top left corner of the screen?",
          answer:`
            <p> No, the MagBak Charger for Tesla is designed to fit perfectly with the top left corner of the screen.</p>
          `,
          url: "#mb3y-location"
        },
        {
          title: "Why is my MagBak Charger for Tesla not charging fast?",
          answer:`
            <p>Try the following:</p>
            <p>
              <ol>
                <li>Use a <i>power adapter*</i> that is compatible with at least USB-C PD20W.</li>
                <li>Make sure there is no gap between the device and the charger.</li>
                <li>Ensure the device and charger coils are aligned.</li>
                <li>Check charger cable to ensure it is properly connected to the USB-C power adapter or USB-C port.</li>
                <li>If using an extension cable, check to see that the charger cable is properly attached.</li>
              </ol>
            </p>
            <p>* Cybertruck charger does NOT require a USB-C power adapter. The USB-C ports on the Cybertruck's center console can provide up to 65W of power.</p>
          `,
          url: "#mb3y-speed"
        },
        {
          title: "Why is the Charger cable not long enough?",
          answer:`
            <p>The length of the cable is ideal for routing the cable to the Model 3|Y center console 12V outlet. We include an extension cable for Cybertruck.</p>
            <p>Make sure that the cable is taut when routing it to ensure it reaches the 12V adapter or USB-C port.</p>
            <p>Please watch the <a href="#mb3y-install">installation videos</a> to see how to route the charger cable.</p>
          `,
          url: "#mb3y-cable-length"
        },
        {
          title: "What are the MagBak Charger for Tesla specifications?",
          answer:`
            <p>
              <ul>
                <li>Model: TMQI-10W</li>
                <li>Material: Aluminum</li>
                <li>Power Input: USB-C PD 20W</li>
                <li>Dimensions: 82 x 17 x 62 (mm)</li>
                <li>Weight: 77 (g)</li>
                <li>Cable Length: 1.5 (m)</li>
              </ul>
            </p>
            <p><img src="/resources/svg/mb-tesla-overview.svg"/></p>
          `,
          url: "#mb3y-specs"
        },
        {
          title: "Where can I find the Tesla Charger manual?",
          answer:`
            <p>You can find the Tesla Charger manual PDF <a href="/resources/pdf/QiTM3-manual-rev4.pdf" target="_blank">here</a>.</p>
          `,
          url: "#mb3y-manual"
        }
      ]
    },
    {
      title: "MagBak Screen Protector for Tesla (Model 3 | Model Y)",
      collapsed: true,
      url: "#sc3y",
      questions: [
        {
          title: "How do I install the Screen Protector on my Tesla's center screen?",
          answer:`
            <p>Watch this video to see the installation instructions.</p>
            <iframe class="landscape" src="https://player.vimeo.com/video/944547647" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            <p>You can find the written PDF instructions <a href="/resources/pdf/SC3Y-Install-Guide-rev1.pdf" target="_blank">here</a>.</p>
          `,
          url: "#sc3y-install"
        },
        {
          title: "What is the difference between Clear Gloss and Clear Matte finish?",
          answer:`
            <p>Clear Gloss finish has a smooth clean surface.</p>
            <p>Clear Matte finish has a slightly textured surface that helps reduce reflections and glare.</p>
            <p><img class="square" src="/resources/img/products/sc3y-on-screen-comparo.jpg"/></p>
          `,
          url: "#sc3y-finish"
        }
      ]
    },
    {
      title: "MagBak RimCase",
      collapsed: true,
      url: "#rimcase",
      questions: [
        {
          title: "How do I install the RimCase?",
          answer:`
            <p>Watch the installation instruction videos.</p>
            <p>
              <ul>
                <li>
                  Model Y 20" Induction<br>
                  <iframe class="landscape" src="https://player.vimeo.com/video/821372024" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </li>
              </ul>
            </p>
            <p>
              <ul>
                <li>
                  Model Y 21" Uberturbine | Model 3 20" Uberturbine<br>
                  <iframe class="landscape" src="https://player.vimeo.com/video/823951557" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </li>
              </ul>
            </p>
            <p>
              See the user manuals for written instuctions.
              <ul>
                <li><a target="_blank" href="/resources/pdf/Manual-RPTMY20I.pdf" title="Model Y Induction">Tesla Model Y 20" Induction</a></li>
                <li><a target="_blank" href="/resources/pdf/Manual-RPTMY21U.pdf" title="Model Y Uberturbine">Tesla Model Y 21" Uberturbine</a></li>
                <li><a target="_blank" href="/resources/pdf/Manual-RPTMY21U.pdf" title="Model 3 Uberturbine">Tesla Model 3 20" Uberturbine</a></li>
              </ul>
            </p>
          `,
          url: "#rimcase-install"
        },
        {
          title: "Which Tesla wheels is the RimCase compatible with?",
          answer:`
            <p>
              <ul>
                <li><a target="_blank" href="https://magbak.com/products/magbak-rim-protector?variant=40301809631313" title="Model Y Induction">Tesla Model Y 20" Induction</a></li>
                <li><a target="_blank" href="https://magbak.com/products/magbak-rim-protector?variant=40301809664081" title="Model Y Uberturbine">Tesla Model Y 21" Uberturbine</a></li>
                <li><a target="_blank" href="https://magbak.com/products/magbak-rim-protector?variant=40301809729617" title="Model 3 Uberturbine">Tesla Model 3 20" Uberturbine</a></li>
              </ul>
            </p>
          `,
          url: "#rimcase-compatibility"
        },
        {
          title: "What colors are available for the RimCase?",
          answer:`
            <p>
              Currently, only colors that match the corresponding wheel color are available.
            </p>
          `,
          url: "#rimcase-colors"
        },
        {
          title: "What area of the wheel does the RimCase cover?",
          answer:`
            <p>
              The RimCase covers and protects the outer rim lip of the wheel.
            </p>
            <p><img src="/resources/img/products/RimCase-Coverage.jpg"/></p>
          `,
          url: "#rimcase-colors"
        },
        {
          title: "Will the RimCase fall off if I drive too fast?",
          answer:`
            <p>
              The RimCase has a special hook designed to ensure it is strongly attached to the wheel. Additionally, the RimCase has damage free 3M adhesive backing for an extra layer of security.
            </p>
            <p><img src="/resources/img/products/mb-rp-stays-attached.jpg"/></p>
          `,
          url: "#rimcase-hook"
        },
        {
          title: "Does the RimCase fit all types of tires?",
          answer:`
            <p>
              The RimCase is designed to work only with the standard tires provided by Tesla.
            </p>
          `,
          url: "#rimcase-tires"
        },
        {
          title: "Can I put the RimCase on hubcaps?",
          answer:`
            <p>
              No. The RimCase is designed to securely clip on to the features of the bare rim..
            </p>
          `,
          url: "#rimcase-hubcaps"
        },
        {
          title: "Will high or low temperatures have any effect on the RimCase?",
          answer:`
            <p>
              The RimCase is designed to mechanically clip and adhere (with 3M Adhesive) onto the rim.
              This provides a very secure mounting mechanism that will withstand high speeds in drastic climates like rain and snow.
            </p>
          `,
          url: "#rimcase-temperature"
        },
        {
          title: "Will the RimCase potentially damage my wheels, my tires, or my car?",
          answer:`
            <p>
              The RimCase is made out of a thermoplastic polymer (ABS or ASA) which is lighter and softer than the material of the wheel.
              Because of this property, it is highly unlikely that RimCase will damage your vehicle.
            </p>
            <p>
              The package also includes protective film that sits in between the wheel and the RimCase segments.
              The protective film helps protect the wheel from hairline scratches caused by road debris, dirt, and other particles.
            </p>
            <p>
              The RimCase is intended to be used with Tesla OEM wheels with their original finish. It is not recommended for use on wheels that have been modified, resurfaced, or repainted.
            </p>
          `,
          url: "#rimcase-use"
        },
        {
          title: "Do I need to re-balance my wheels after I install the RimCase?",
          answer:`
            <p>
              You DO NOT need to re-balance your wheels. The RimCase is made out of a light material and is designed to be installed evenly around the entire perimeter of the rim.
            </p>
          `,
          url: "#rimcase-balance"
        },
        {
          title: "Will the RimCase change or deform the tire?",
          answer:`
            <p>
              No. The RimCase DOES NOT place any mechanical force on the tire.
            </p>
          `,
          url: "#rimcase-tire"
        },
        {
          title: "Is the RimCase designed for a specific wheel?",
          answer:`
            <p>
              Yes. RimCase has different variants; Each is designed for a specific wheel.
            </p>
            <p>
              Here is the list of currently supported wheels:
            </p>
            <p>
              <ul>
                <li><a target="_blank" href="https://magbak.com/products/magbak-rim-protector?variant=40301809631313" title="Model Y Induction">Tesla Model Y 20" Induction</a></li>
                <li><a target="_blank" href="https://magbak.com/products/magbak-rim-protector?variant=40301809664081" title="Model Y Uberturbine">Tesla Model Y 21" Uberturbine</a></li>
                <li><a target="_blank" href="https://magbak.com/products/magbak-rim-protector?variant=40301809729617" title="Model 3 Uberturbine">Tesla Model 3 20" Uberturbine</a></li>
              </ul>
            </p>
          `,
          url: "#rimcase-size"
        },
        {
          title: "Is the RimCase obvious on my wheel?",
          answer:`
            <p>
              The RimCase is designed to match the look and feel of the wheel.
            </p>
            <p>
              <img src="/resources/img/mb-rptmy20i-comparo.jpg"/>
            </p>
          `,
          url: "#rimcase-look"
        },
        {
          title: "How is RimCase different from other wheel/rim protectors?",
          answer:`
            <p>
              <ul>
                <li>Practically invisible: Matches the look and feel of the wheel.</li>
                <li>Mechanically mounted: Secure and reliable even at high speeds.</li>
                <li>Modular design: Easily replace damaged sections in case of curb rash.</li>
                <li>Durable materials: Long lasting aesthetics and protection. </li>
                <li>Easy installation: No need for special equiment or service shops.</li>
                <li>Maintenance free: Set it and forget it.</li>
              </ul>
            </p>
          `,
          url: "#rimcase-difference"
        },
        {
          title: "Can wheel alignment and balancing still be done with a RimCase installed?",
          answer:`
            <p>
              Yes. The RimCase does not interfere with wheel alignment or balancing. The RimCase is equally distributed around the perimeter of the rim.
            </p>
          `,
          url: "#rimcase-alignment"
        },
        {
          title: "Does the RimCase need to be removed if the tire is being changed?",
          answer:`
            <p>
              The RimCase can easily be uninstalled in the rare occasion that a tire needs to be changed.
            </p>
            <p>
              There will be extra RimCase adhesives available for purchase if they need to be replaced.
            </p>
          `,
          url: "#rimcase-tire-change"
        },
        {
          title: "How do I clean the RimCase?",
          answer:`
            <p>
              The RimCase can be washed and cleaned the same way as a bare rim.
            </p>
          `,
          url: "#rimcase-wash"
        },
        {
          title: "Can I put the RimCase through a car wash?",
          answer:`
            <p>
              Yes. The RimCase design and materials allows it to widstand normal carwash conditions.
            </p>
          `,
          url: "#rimcase-carwash"
        },
        {
          title: "Do I need a professional to install the RimCase on my wheels?",
          answer:`
            <p>
              No. The RimCase is easy to install. All of the necessary tools and materials are included and can be done at home.
            </p>
          `,
          url: "#rimcase-professional"
        },
        {
          title: "What equipment or tools are required to install the RimCase?",
          answer:`
            <p>
              No special tools are required to install the RimCase. Each RimCase includes all of the necessary items for installation.
            </p>
          `,
          url: "#rimcase-install"
        },
        {
          title: "What happens if I damage only a few RimCase segments?",
          /*answer:`
            <p>
              You can easily replace only the damaged RimCase segments and leave the rest intact. Single wheel RimCase sets are available for purchase at our <a href="https://magbak.com" target="_blank">online store</a>.
            </p>
          `,
          */
          answer:`
            <p>
              You can easily replace only the damaged RimCase segments and leave the rest intact.
            </p>
          `,
          url: "#rimcase-replace"
        },
        {
          title: "What is the RimCase replacement policy?",
          answer:`
            <p>
              MagBak will cover manufacturing defects and missing parts. For more details refer to the replacement policy found <a href="https://magbak.com/policies/refund-policy" target="_blank">here</a>.
            </p>
          `,
          url: "#rimcase-replacement-policy"
        },
        {
          title: "What is the RimCase return policy?",
          answer:`
            <p>
              MagBak will only accept returns if the product is unused (in the original packaging) and the request is made within 30 days of order delivery. For more details refer to the return policy found <a href="https://magbak.com/policies/refund-policy" target="_blank">here</a>.
            </p>
            <p>
              RimCase segments or items that are used will not be accepted.
            </p>
          `,
          url: "#rimcase-return-policy"
        },
        {
          title: "Can I exchange the RimCase if I don't like it?",
          answer:`
            <p>
              MagBak will only accept exchanges if the product is unused (in the original packaging) and the request is made within 30 days of order delivery. For more details refer to the exchange policy found <a href="https://magbak.com/policies/refund-policy" target="_blank">here</a>.
            </p>
            <p>
              RimCase segments or items that are used will not be accepted.
            </p>
          `,
          url: "#rimcase-exchange-policy"
        },
        {
          title: "Will the RimCase work with aftermarket Tesla wheels?",
          answer:`
            <p>
              We've conducted all our testing using original Tesla wheels. If you've made modifications to your wheels, the RimCase may still work if the shape remains unchanged. However, we cannot guarantee how well the RimCase functionality will perform in such cases.
            </p>
          `,
          url: "#rimcase-aftermarket"
        },
        {
          title: "Will the paint peel off of my Tesla wheels if I use the clear film?",
          answer:/*`
            <p>
              The RimCase components are meticulously designed and thoroughly tested to function seamlessly with original Tesla wheels. However, if any modifications such as ceramic coating, paint coating, or spray paint coating are applied to the Tesla wheel, we cannot guarantee the optimal functionality of the RimCase components in such cases.
            </p>
          `*/
          `
          <p>
            The RimCase components are meticulously designed and thoroughly tested to function seamlessly with original Tesla wheels. The RimCase is intended to be used with Tesla OEM wheels with their original finish. It is not recommended for use on wheels that have been modified, resurfaced, or repainted.
          </p>
          `,
          url: "#rimcase-film"
        },
        {
          title: "Why do the wheels make noise when driving with the RimCase on?",
          answer:`
            <p>
              In some instances, there is interaction between the tire and the RimCase segments. This can occur on the section of the wheel that is in contact with the ground. This noise is normal and does not affect the RimCase segments or wheel.
            </p>
          `,
          url: "#rimcase-flex"
        },
      ]
    },
    /*
    {
      title: "MagBak Bridge",
      collapsed: true,
      questions: [
        {
          title: "Do I need a MagBak case to mount to the MagBak Bridge?",
          answer:`
            <p>We recommend using a MagBak case with the Bridge to take adanatage of strong magnetic mounting and auto-alignment features.</p>
            <p>Alternatively, you can mount the iPhone 12 / 13 series phone by itself (or with a case that has MagSafe technology) directly to the MagSafe charger.</p>
          `,
          url: "#bridge-magbak-case"
        },
        {
          title: "What does the MagBak Bridge do?",
          answer:`
            <p>The Bridge turns Apple's MagSafe charger into a strong magnetic mount. Great to mount to a car's dashboard using MagStick (one included with every order).</p>
            <p>You can also use the Bridge on the <a target="_blank" href="https://magbak.com/products/magbak-stand">MagBak stand</a> to create the perfect home for your phone.</p>
          `,
          url: "#bridge-function"
        },
        {
          title: "How do I attach the MagBak Bridge to my car's dashboard?",
          answer:`
            <p>First, <a href="#magstick-install">install</a> the included MagStick on the dashboard. You can then magnetically attach the Bridge to the MagStick.</p>
          `,
          url: "#bridge-dashboard"
        },
        {
          title: "Is the MagSafe charger included with the MagBak Bridge?",
          answer:`
            <p>No. The MagSafe charger is a separate item that needs to be purchased from Apple.</p>
          `,
          url: "#bridge-magsafe-charger"
        },
        {
          title: "What are the dimensions of the MagBak Bridge?",
          answer:`
            <p><img src="/resources/img/bridge-dimensions.jpg"/></p>
          `,
          url: "#bridge-dimensions"
        }
      ]
    },
    */
    {
      title: "MagStick",
      collapsed: true,
      url: "#magstick",
      questions: [
        {
          title: "How do I install MagStick on a surface?",
          answer:`
            <p>Watch this video to see the installation instructions.</p>
            <iframe class="landscape" src="https://player.vimeo.com/video/140133503" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            <p>1) Clean surface with alcohol pads and let it dry.</p>
            <p>2) Orient MagStick so that thicker side is on top.</p>
            <img class="landscape" src="/resources/img/magstick-install-1.jpg"/>
            <p>3) Peel off the red 3M backing. Press and hold MagStick onto surface for 45 seconds.</p>
            <p>4) Wait 5 minutes before mounting your phone.</p>
          `,
          url: "#magstick-install"
        },
        {
          title: "Will the adhesive damage my car's dash?",
          answer:`
            <p>The 3M adhesive backing will not damage your car's dash.</p>
            <p>The adhesive comes off clean without leaving residue behind.</p>
          `,
          url: "#magstick-adhesive"
        },
        {
          title: "What are the dimensions of the MagStick?",
          answer:`
            <p><img src="/resources/img/magstick-dimensions.jpg"/></p>
          `,
          url: "#magstick-dimensions"
        }
      ]
    },
    {
      title: "MagStache",
      collapsed: true,
      url: "#magstache",
      questions: [
        {
          title: "How do I install MagStache on my phone?",
          answer:`
            <p>Watch this video to see the installation instructions.</p>
            <iframe class="landscape" src="https://player.vimeo.com/video/312876936" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
          `,
          url: "#stache-install"
        },
        {
          title: "Can the MagStache be used under a case or on the back of the phone with a case over it?",
          answer:`
            <p>We recommend that the MagStache be on the outter surface of a phone case.</p>
            <p>Putting a case over the MagStache will significantly reduce the magnetic force and is NOT guaranteed to work.</p>
          `,
          url: "#stache-covered"
        },
        {
          title: "What are the dimensions of the MagStache?",
          answer:`
            <p><img src="/resources/img/magstache-dimensions.jpg"/></p>
          `,
          url: "#magstache-dimensions"
        }
      ]
    },
    {
      title: "MagBak Halo",
      collapsed: true,
      url: "#halo",
      questions: [
        {
          title: "What does the Halo flashing indicator LED mean?",
          answer:`
            <p>Here is a list of the different indicator LED codes:</p>
            <ol>
              <li>
                <p><span class="hled hled-homing"></span> Orange Flashing after power on.</p>
                <p>Halo is in the process of moving the phone tray to the top position. The LED will turn green once the process is completed.</p>
              </li>
              <li>
                <p><span class="hled hled-sanitizing"></span> Slow Yellow Flashing after phone insertion.</p>
                <p>Halo is in the process sanitizing the phone. The LED will turn green once the process is completed.</p>
              </li>
              <li>
                <p><span class="hled hled-rejecting"></span> Fast Orange Flashing after phone insertion.</p>
                <p>Halo rejected the phone sanitizing process. This is for safety and occurs if Halo senses the phone was inserted unevenly. Take phone out all the way and try again.</p>
              </li>
              <li>
                <p><span class="hled hled-aborting"></span> Red Flashing during the phone sanitizing process.</p>
                <p>Halo has aborted the phone sanitizing process. This is for safety and occurs if Halo senses and unexpected condition. Take phone out all the way and try again.</p>
              </li>
              <li>
                <p><span class="hled hled-home-error"></span> Yellow-Yellow-Yellow-Yellow error code.</p>
                <p>Halo can't sense the phone tray at the top position.</p>
                <p>To fix this unplug Halo from power and check to make sure nothing is stuck inside.</p>
                <p>Plug Halo back into power once all obstructions are cleared.</p>
                <p>If the problem persists it means that Halo has been damaged.</p>
              </li>
              <li>
                <p><span class="hled hled-heat-error"></span> Orange-Yellow-Yellow-Red error code.</p>
                <p>Halo heat protection has been triggered.</p>
                <p>It occurs when Halo is in constant use to protect the logevity of the UV-C LEDs.</p>
                <p>Simply waiting a couple of minutes is enough time (depending on the ambient temperature) to cool down Halo for further use.</p>
              </li>
            </ol>
          `,
          url: "#halo-led-codes"
        },
        {
          title: "How do I reset my Halo?",
          answer:`
            <p>Simply unplug the power, wait for 5 seconds, and plug the power back.</p>
          `,
          url: "#halo-reset"
        },
        {
          title: "Why does Halo make a humming sound when I first plug it to power?",
          answer:`
            <p>When Halo is powered on, it moves the internal phone tray to the top positon. What you hear is the motor in action.</p>
          `,
          url: "#halo-power"
        },
        {
          title: "Halo is making a grinding sound. Is everything ok?",
          answer:`
            <p>A few possibilities can cause this:</p>
            <ol>
              <li>
                <p>Halo is stuck and can't bring the phone tray to the top position.</p>
                <p>To fix this unplug Halo from power and check to make sure nothing is stuck inside.</p>
                <p>Plug Halo back into power once all obstructions are cleared.</p>
                <p>If the problem persists, it means that Halo has been damaged.</p>
              </li>
              <li>
                <p>Halo can't sense the phone tray at the top position and continues to attempt to bring it to the top.</p>
                <p>If this is the case, Halo's internal limit switch is damanged.</p>
              </li>
            </ol>
          `,
          url: "#halo-noise"
        },
        {
          title: "Where can I find the Halo manual?",
          answer:`
            <p>You can find the Halo manual PDF <a href="/resources/pdf/MBH-Halo-Manual-V4.pdf" target="_blank">here</a>.</p>
          `,
          url: "#halo-manual"
        }
      ]
    },
    {
      title: "MagBak KeyTag",
      collapsed: true,
      url: "#keytag",
      questions: [
        {
          title: "How do I put my keys into the KeyTag?",
          answer:`
            <p>Watch this video to see the instructions.</p>
            <iframe class="landscape" src="https://player.vimeo.com/video/764676888" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            <p>You can find the written instructions PDF <a href="/resources/pdf/mb-keytag-manual-v1.pdf" target="_blank">here</a>.</p>
          `,
          url: "#keytag-assemble"
        },
        {
          title: "How do I install the AirTag into the KeyTag?",
          answer:`
            <p>Watch this video to see the instructions.</p>
            <iframe class="landscape" src="https://player.vimeo.com/video/764677150" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            <p>You can find the written instructions PDF <a href="/resources/pdf/mb-keytag-manual-v1.pdf" target="_blank">here</a>.</p>
          `,
          url: "#keytag-airtag"
        },
        {
          title: "How do I remove the AirTag from the KeyTag?",
          answer:`
            <p>Watch this video to see the instructions.</p>
            <iframe class="landscape" src="https://player.vimeo.com/video/764735556" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            <p>You can find the written instructions PDF <a href="/resources/pdf/mb-keytag-manual-v1.pdf" target="_blank">here</a>.</p>
          `,
          url: "#keytag-airtag-remove"
        },
        {
          title: "How do I set up my AirTag?",
          answer:`
            <p>Apple's AirTag set up instructions are <a href="https://support.apple.com/en-us/HT210973" target="_blank">here</a>.</p>
          `,
          url: "#keytag-airtag-setup"
        },
        {
          title: "Can I return the AirTag for a refund, exchange or replacement?",
          answer:`
            <p>It is difficult to refurbish, repackage, and restock AirTags that are shipped to customers.</p>
            <p>For this reason, AirTag purchases made through magbak.com are non-refundable. Exchanges and replacements are also not available.</p>
          `,
          url: "#keytag-airtag-refund"
        },
        {
          title: "How many keys fit in the KeyTag?",
          answer:`
            <p>The KeyTag fits 4-6 keys.</p>
            <p>If you have less than 4 keys, you can use additional washers to fill the gap.</p>
            <p>The additional washers should be placed between the keys.</p>
          `,
          url: "#keytag-fit"
        },
        {
          title: "How should I arrange my keys in the KeyTag?",
          answer:`
            <p>We recommend that larger keys be placed on the side of the KeyTag that's opposite the AirTag.</p>
            <img class="landscape" src="/resources/img/mb-key-arrangement.jpg"/>
          `,
          url: "#keytag-arrangement"
        },
        {
          title: "What's the largest key that fits in the KeyTag?",
          answer:`
            <p>The KeyTag can fit 1 key with a length of 58mm. The rest of the keys need to have a max length of 53mm.</p>
          `,
          url: "#keytag-key-size"
        },
        {
          title: "Where can I find the KeyTag manual?",
          answer:`
            <p>You can find the KeyTag manual PDF <a href="/resources/pdf/mb-keytag-manual-v1.pdf" target="_blank">here</a>.</p>
          `,
          url: "#keytag-manual"
        }
      ]
    }
  ]
};



export const FAQS_CATEGORY_INDEX = PRODUCT_FAQS.categories.map((category)=>{
  return {
    title: category.title,
    questions: category.questions.map((question)=>{
      let elem = question.answer
      // console.log("INNTER TEXT: ", elem);
      return {
        title: question.title,
        answer: question.answer.innerText
      }
    })
  };
});
