import React from 'react';
import Page from './Page'

import Separator from '../elements/Separator';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Page.css';

class RedirectPage extends Page {
  render() {
    return (
      <div className="RedirectPage Page">
        <PageHeaderOutline title="Redirecting" top={this._backButton()}></PageHeaderOutline>
        <p>You are being redirected!</p>
      </div>
    );
  }
}

export default RedirectPage;
