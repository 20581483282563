import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Menu from './Menu';
import './TopMenu.css';
import MenuItemLayout from '../layouts/MenuItemLayout';
import { LEFT_MENU } from '../../constants/menus/LeftMenu';
import { expandMenu } from '../../actions/MenuActions';
import { openPopup } from '../../actions/PopupActions';
import PlainButton from '../elements/PlainButton';
import RectButton from '../elements/RectButton';
import DropControl from '../controls/DropControl';
import SearchLayout from '../layouts/SearchLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { dot } from '../../lib/obj';
import { logoutSession } from '../../actions/SessionActions';

class TopMenu extends Menu {

  onStore(evt) {
    window.location = "https://magbak.com";
  }

  onHome(evt,url) {
    this.props.history.push("/");
  }

  render() {
    return (
      <div className="TopMenu" ref="root">
        <div className="Content">
          <img className="Logo" src="/resources/img/1lss-logo-wt.png" onClick={(evt)=>{this.onHome(evt)}}/>
          <div className="Title" onClick={(evt)=>{this.onHome(evt)}}>MagBak&nbsp;<i>Help</i></div>
          <RectButton className="Aside" theme="darkblue" onClick={(evt)=>{this.onStore(evt)}}>SHOP</RectButton>
          <SearchLayout/>
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => {
  let menuName = props.name || "top";
  return {
    menuName: menuName,
    menu: state.menus[menuName] || {expanded:true},
    session: state.session
  }
};

const mapDispatch = (dispatch) => {
  return {
    expandMenu: opts => dispatch(expandMenu(opts)),
    openPopup: opts => dispatch(openPopup(opts)),
    logoutSession: opts => dispatch(logoutSession(opts))
  }
};

export default connect(
  mapState,
  mapDispatch
)(withRouter(TopMenu))
