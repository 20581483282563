import React from 'react';
import { connect } from 'react-redux'
import { Router, Route, Link, Redirect } from "react-router-dom";
import Dashboard from './pages/Dashboard';
import ConstructionPage from './pages/ConstructionPage';
import ECStartPage from './pages/ECStartPage';
import ECExperienceSurveyPage from './pages/ECExperienceSurveyPage';
import ECUnboxingSurveyPage from './pages/ECUnboxingSurveyPage';
import FaqPage from './pages/FaqPage';
import IncompleteOrderPage from './pages/IncompleteOrderPage';
import MBCStartPage from './pages/MBCStartPage';
import NewOrderPage from './pages/NewOrderPage';
import NotFoundPage from './pages/NotFoundPage';
import OrderStatusPage from './pages/OrderStatusPage';
import OrderStatusRequestPage from './pages/OrderStatusRequestPage';
import OrderHelpPage from './pages/OrderHelpPage';
import OrderRMAPage from './pages/OrderRMAPage';
import UpdateOrderPage from './pages/UpdateOrderPage';
import CancelOrderPage from './pages/CancelOrderPage';
import ExchangeOrderPage from './pages/ExchangeOrderPage';
import ReturnOrderPage from './pages/ReturnOrderPage';
import RefundOrderPage from './pages/RefundOrderPage';
import RefundOrderRequestPage from './pages/RefundOrderRequestPage';
import ReplaceOrderPage from './pages/ReplaceOrderPage';
import RedirectPage from './pages/RedirectPage';
import SearchPage from './pages/SearchPage';
import SalesPage from './pages/SalesPage';
import Popups from './popups/Popups';
import LeftMenu from './menus/LeftMenu';
import TopMenu from './menus/TopMenu';
import BottomMenu from './menus/BottomMenu';
import { dot } from '../lib/obj';
import { parseQuery } from '../lib/formats';
import './Main.css';

/*
 * Fix scroll position when jumpoing to new pages
**/
import { createBrowserHistory } from 'history'
export const history = createBrowserHistory();
history.listen((location, action) => {
  if (action === "PUSH") {
    window.scrollTo(0,0);
  }
});

class Main extends React.Component {

  render() {
    let pages = true ? this.loggedInUI() : <div/>;
    return (
      <Router history={history}>
        <div className="Frame">
          <Popups/>
          <div className="TopSpace"/>
          <TopMenu/>
          {pages}
          <BottomMenu/>
        </div>
      </Router>
    );
  }

  /**
   * Helps render the logged in UI
  **/
  loggedInUI() {
    return (
      <div className="loggedInUI">
        <div className="Pages">
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/dashboard" render={({match})=>{
            return <Dashboard key="all" title="Home"/>
          }} />
          <Route exact path="/elite" render={({match})=>{
            let query = parseQuery(history.location.search);
            return <ECStartPage key="faqPage" title="Elite Case Intro" back="Back" query={query}/>
          }} />
          <Route exact path="/classic" render={({match})=>{
            let query = parseQuery(history.location.search);
            return <MBCStartPage key="faqPage" title="Classic Case Intro" back="Back" query={query}/>
          }} />
          <Route exact path="/faq" render={({match})=>{
            let query = parseQuery(history.location.search);
            if (query.question == 'cg-install' || query.question == 'gen2-features') {
              return <ECStartPage key="faqPage" title="Elite Case Intro" back="Back" query={query}/>
            }
            return <FaqPage key="faqPage" title="FAQ" back="Back" query={query}/>
          }} />
          <Route exact path="/order" render={({match})=>{
            //return <OrderHelpPage key="orderHelp" title="Order Help" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-faq-8ab95e0d-6350-43ff-ab5b-8c984e4387cf");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/order/cancel" render={({match})=>{
            //return <CancelOrderPage key="orderCancel" title="Cancel Help" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-faq-b42af823-6597-4e63-8272-5adbb1d1b57a");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/order/incomplete" render={({match})=>{
            //return <IncompleteOrderPage key="orderIncomplete" title="Incomplete Order" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-faq-1c097cb3-b0a1-4925-b039-44fcaacfd70d");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/order/new" render={({match})=>{
            //return <NewOrderPage key="orderNew" title="New Order Help" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/order/update" render={({match})=>{
            //return <UpdateOrderPage key="orderUpdate" title="Update Order Help" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-login");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/order/return" render={({match})=>{
            //return <ReturnOrderPage key="orderReturn" title="Return" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-faq-1ed08c22-83c5-4f43-8b11-33edb02e99ad");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/order/status" render={({match})=>{
            //return <OrderStatusPage key="orderStatus" title="Order Status" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-faq-953a0f00-58af-462a-9024-1717d694de42");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/order/status-request" render={({match})=>{
            //return <OrderStatusRequestPage key="orderStatusRequest" title="Order Status Request" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-faq-953a0f00-58af-462a-9024-1717d694de42");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/rma" render={({match})=>{
            //return <OrderRMAPage key="orderRMA" title="Order Help" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-faq-8ab95e0d-6350-43ff-ab5b-8c984e4387cf");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/rma/exchange" render={({match})=>{
            //return <ExchangeOrderPage key="orderExchange" title="Exchange" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-faq-1ed08c22-83c5-4f43-8b11-33edb02e99ad");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/rma/refund" render={({match})=>{
            //return <RefundOrderPage key="orderRefund" title="Refund" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-faq-1ed08c22-83c5-4f43-8b11-33edb02e99ad");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/rma/refund-request" render={({match})=>{
            //return <RefundOrderRequestPage key="orderRefundRequest" title="Refund" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-faq-1ed08c22-83c5-4f43-8b11-33edb02e99ad");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/rma/replace" render={({match})=>{
            //return <ReplaceOrderPage key="orderReplace" title="Replace" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-faq-6aba9fcc-ea4e-44da-b458-678541668a1f");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/search" render={({match})=>{
            let query = parseQuery(history.location.search);
            return <SearchPage key="search" title="Search" back="Back" term={`${query.term}`.replace('+',' ')}/>
          }} />
          <Route exact path="/sales" render={({match})=>{
            //return <SalesPage key="sales" title="Sales" back="Back"/>
            window.location.replace("https://magbak.customerdesk.io/#rp-customer-widget-prechat_form-9281b08b-40ad-4e8c-be50-8788d077ffa9");
            return <RedirectPage key="Redirect" title="Redirecting..." back="Back"/>
          }} />
          <Route exact path="/construction" render={({match})=>{
            return <ConstructionPage key="construction" title="Under Construction" back="Back"/>
          }} />
          <Route exact path="/files/:loc" render={({match})=>{
            return <ConstructionPage key="construction" title="Under Construction" back="Back"/>
          }} />
          <Route exact path="/survey/ec/unboxing" render={({match})=>{
            return <ECUnboxingSurveyPage key="ecUnboxingSurveyPage" title="Elite Case Unboxing" back="Back"/>
          }} />
          <Route exact path="/survey/ec/experience" render={({match})=>{
            return <ECExperienceSurveyPage key="ecExperienceSurveyPage" title="Elite Case Experience" back="Back"/>
          }} />
        </div>
      </div>
    );
  }

}

const mapState = (state, props) => {
  return {
    inventory: state.inventory,
    login: dot(state.session,"login")
  }
};

const mapDispatch = (dispatch) => {
  return {}
};

export default connect(
  mapState,
  mapDispatch
)(Main)
